import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useCallback } from 'react';
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import TableModule from '../../components/TableModule';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark,
    faClipboardCheck,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const RolForm = ({ handleLogout }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [code, setCode] = useState("");
    const [description, setDescription] = useState("");
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const itemsPerPage = 10;
    const [selectedModules, setSelectedModules] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (id) {
            const fetchRol = async () => {
                try {
                    const response = await apiClient.get(`/admin/roles/${id}`);
                    setCode(response.data.code);
                    setDescription(response.data.description);

                } catch (error) {
                    console.error("Error al cargar el rol", error);
                }
            };
            fetchRol();
        }
    }, [id]);

    const fetchAllData = useCallback(async (page = 1) => {
        try {
            setLoading(true);
            const response = await apiClient.get(`/admin/permissions?page=${page}`);
            if (Array.isArray(response.data.results)) {
                setData(response.data.results);
                setTotalItems(response.data.total);
            } else {
                setData([response.data.results]);
                setTotalItems(1);
            }
        } catch (error) {
            setIsErrorVisible(true);
        } finally {
            setIsSuccessVisible(true);
            setLoading(false);
        }
    }, []);
    useEffect(() => {
        fetchAllData(currentPage);
    }, [fetchAllData, currentPage]);

    const handleRefresh = () => {
        fetchAllData(currentPage);
    };

    const handleCheckboxChange = (code) => {
        setSelectedModules(prevSelected =>
            prevSelected.includes(code)
                ? prevSelected.filter(selectedCode => selectedCode !== code)
                : [...prevSelected, code]
        );
    };
    const handleSelectAll = () => {
        if (selectedModules.length === data.length) {
            setSelectedModules([]);
        } else {
            setSelectedModules(data.map(item => item.moduleCode));
        }
    };

    const handleSearch = useCallback(async (page = 1) => {
        try {
            // Construir la URL con los query params
            const params = new URLSearchParams();
            if (search) params.append('search', search);

            const response = await apiClient.get(`/admin/permissions?${params.toString()}&page=${page}`);

            if (Array.isArray(response.data.results)) {
                setData(response.data.results); // Si es un array, lo dejamos tal cual
            } else {
                setData([response.data.results]); // Si es un objeto, lo encapsulamos en un array
            }
            setTotalItems(response.data.total);
        } catch (error) {
        }
    }, [search]);
    const handleFresh = async (code) => {
        handleSearch(1)
    };

    const columns = [
        { title: "Id", key: "id" },
        { title: "Código", key: "code" },
        { title: "Descripción", key: "name" },
        { title: "Seleccionar", key: "acciones" },
    ];

    const renderRow = (item, index) => (
        <>
            <td>{item.id}</td>
            <td>{item.code}</td>
            <td>{item.description}</td>
            <td>
                <input
                    type="checkbox"
                    checked={selectedModules.includes(item.code)}
                    onChange={() => handleCheckboxChange(item.code)}
                />
            </td>
        </>
    );


    const validateFields = () => {
        let validationErrors = {};
        const onlyLetters = /^[a-zA-Z]+$/;
        if (!code) {
            validationErrors.code = "El Campo es Obligatorio";
        } else if (!onlyLetters.test(code)) {
            validationErrors.code = "Solo se permiten letras";
        }
        if (!description) validationErrors.description = "El Campo es Obligatorio";
        return validationErrors;
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let response;
            if (id) {
                const payloadUpdate = {
                    description
                };
                response = await apiClient.patch(
                    `/admin/roles/${id}`,
                    payloadUpdate
                );
                if (response.status === 200) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setIsLoginVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/RolList");
                }
            } else {
                const payload = {
                    code,
                    description,
                    permissions: selectedModules
                };
                response = await apiClient.post("/admin/roles", payload);
                if (response.status === 201) {
                    setIsSuccessVisible(true);
                    setIsErrorVisible(false);
                    setErrors({});
                    setTimeout(() => {
                        setIsSuccessVisible(false);
                    }, 4000);
                    navigate("/RolList");
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            } else if (error.response?.data?.message.startsWith("ROLE_ALREADY_EXIST")) {
                setErrorMessage("El Rol ya existe.");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
            else {
                setErrorMessage("Oops!. Ocurrió un error al crear el Permiso");
                setIsLoginVisible(false);
                setIsErrorVisible(true);
                setIsSuccessVisible(false);
                setTimeout(() => {
                    setIsErrorVisible(false);
                }, 4000);
            }
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Roles" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/RolList");
                        }}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Información del ROl</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.code ? "red" : "inherit" }}>
                            Código
                        </label>
                        <input
                            type="text"
                            placeholder="Código"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            readOnly={!!id}
                        />
                        {errors.code && <p className="error-message">{errors.code}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.description ? "red" : "inherit" }}>
                            Descripción
                        </label>
                        <input
                            type="text"
                            placeholder="Descripción"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        {errors.description && <p className="error-message">{errors.description}</p>}
                    </div>
                </div>
                {!id && (
                    <div className="basic-info-form-grid1">
                        <div className="basic-info-form-group">
                            <label>Permisos</label>
                            {/*SEARCH */}
                            <div className="filter-form">
                                <div className="basic-info-form-group">
                                    <input
                                        type="text"
                                        id="customer"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder="Permiso"
                                    />
                                </div>
                                <button className="search-button-customer" onClick={handleFresh}>
                                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                    Buscar
                                </button>

                            </div>
                            <TableModule
                                title="Lista de Permisos"
                                rows={data}
                                columns={columns}
                                icon={faClipboardCheck}
                                renderRow={renderRow}
                                currentPage={currentPage}
                                totalItems={totalItems}
                                itemsPerPage={itemsPerPage}
                                onPageChange={setCurrentPage}
                                onRefresh={handleRefresh}
                                onSelectAll={handleSelectAll}
                                isAllSelected={selectedModules.length === data.length}
                                loading={loading}
                                select={true}
                            />
                        </div>
                    </div>
                )}
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/RolList");
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={id ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {id ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    id
                        ? "El Permiso se ha Actualizado Correctamente."
                        : "El Permiso se ha Creado Correctamente."
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isLoginVisible}
                onClose={() => setIsLoginVisible(false)}
                onLogout={handleLogout}
            />

        </div>
    );
};

export default RolForm;
