import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../axios";
import SuccessProcess from '../Notifications/SuccessProcess';
import LoginNotification from "../Notifications/LoginNotification";
import ErrorNotification from '../Notifications/ErrorNotification';
import Loader from "../Loader";

const AddUserContract = ({ isVisible, onClose, contract }) => {
    const navigate = useNavigate();
    const [successVisible, setSuccessVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [numCli, setNumCli] = useState(1);
    const [numCont, setNumCont] = useState(0);
    const [cliente, setCliente] = useState('');
    const [mainModuleTemplateId, setMainModuleTemplateId] = useState(0);

    useEffect(() => {
        if (contract) {
            const fetchAllData = async () => {
                try {
                    setLoading(true);
                    const response = await apiClient.get(`/contracts/${contract.numCont}?skipLogin=true`);
                    if (response.status === 200) {
                        setNumCont(contract.numCont);
                        setCliente(contract.cliente);
                        setMainModuleTemplateId(response.data.mainModuleTemplateId);
                    }
                } catch (error) {
                    setIsErrorVisible(true);
                } finally {
                    setLoading(false);
                }

            };
            fetchAllData();
        }

    }, [contract]);


    const handleCounterChange = (type) => {
        setNumCli((prev) => {
            if (type === "increment") {
                return prev + 1;
            } else if (type === "decrement" && prev > 1) {
                return prev - 1;
            }
            return prev;
        });
    };

    const handleConfirm = async () => {
        try {
            setLoading(true);

            const payload = {
                contractId: numCont || 0,
                purchasedModules: [
                    {
                        templateId: mainModuleTemplateId,
                        extraUsers: numCli
                    },
                ],
            };
            const response = await apiClient.post('/orders', payload);
            if (response.status === 201) {
                setSuccessVisible(true);
                const orderNumber = response.data?.order.id;
                navigate(`/PurchaseSummary/${orderNumber}`);
            }

        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }

        } finally {
            setLoading(false);
        }

    }

    const handleCloseSuccess = () => {
        setSuccessVisible(false);
        onClose();
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info2">
                    <div className="message-container-info">
                        <h3 className="basic-info-form-title">Actualización de datos</h3>
                    </div>
                    <div className="message-container-info">
                        <div className="basic-info-form-group">
                            <label>Nro. Contrato</label>
                            <input
                                type="text"
                                placeholder="Nro. Contrato"
                                value={numCont}
                                onChange={(e) => setNumCont(e.target.value)}
                                readOnly
                            />
                        </div>
                        <div className="basic-info-form-group">
                            <label >Cliente</label>
                            <input
                                type="text"
                                name="Cliente"
                                value={cliente}
                                onChange={(e) => setCliente(e.target.value)}
                                readOnly
                            />
                        </div>
                        <div className="basic-info-form-group2">
                            <label className="basic-title">Número de Usuarios</label>
                            <div className="counter-group">
                                <div className="counter">
                                    <div className="custom-counter">
                                        <button onClick={() => handleCounterChange("decrement")}>-</button>
                                        <span>{numCli}</span>
                                        <button onClick={() => handleCounterChange("increment")}>+</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="button-container-info">
                        <button className="cancel-btn-info" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="ok-btn-info" onClick={handleConfirm}>
                            OK
                        </button>
                    </div>
                    {loading && <Loader />}
                    <SuccessProcess
                        message={"Orden de usuarios extras creada correctamente."}
                        isVisible={successVisible}
                        onClose={handleCloseSuccess}
                        redirectOnOk={false}
                    />
                    <ErrorNotification
                        message="Ups! Ocurrio un Problema"
                        isVisible={isErrorVisible}
                        onClose={() => setIsErrorVisible(false)}
                    />
                    <LoginNotification
                        message="Vuele a iniciar sesión"
                        isVisible={isInfoVisible}
                        onClose={() => setIsInfoVisible(false)}
                    />
                </div>
            </div>
        )
    );
};

export default AddUserContract;
