import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
    faClose,
    faFileInvoiceDollar
}
    from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import Loader from "../Loader";
import Table from '../Table';

const DebtDetail = ({ code, closeModal }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchDetail = async () => {
            try {
                setLoading(true);
                const response = await apiClient.get(`/distributors/${code}/debt`);
                setIsSuccessVisible(true);
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                    setTotalItems(response.data.data.length);
                } else {
                    setData([response.data.data]);
                    setTotalItems(1);
                }
            } catch (error) {
                setIsErrorVisible(true);
                if (error.response && error.response.status === 401) {
                    setIsInfoVisible(true);
                }
            }
            finally {
                setLoading(false);
                setIsErrorVisible(false);
                setIsSuccessVisible(false);
            }
        };
        fetchDetail();

        
        

    }, [code]);
    
    const handleRefresh = () => {
        
      };
    const columns = [
        { title: "Cliente", key: "code" },
        { title: "Rázon Social", key: "socialReason" },
        { title: "Factura", key: "invoice" },
        { title: "Importe", key: "import" },
        { title: "Referencia", key: "refer" },
        { title: "Fecha Emisión", key: "created" },
        { title: "Fecha Máxima de Pago", key: "max" },
        { title: "Fecha Mínima de Pago", key: "min" },
        { title: "Referencia 2", key: "refer2" },
        
      ];
    
      const renderRow = (item, index) => (
        <>
          <td>{item.customerCode}</td>
          <td>{item.customerName}</td>
          <td>{item.invoiceNumber}</td>
          <td className="align-right">${item.balance.toFixed(2)}</td>
          <td>{item.reference}</td>
          <td>{new Date(item.issueAt).toLocaleString()}</td>
          <td>{new Date(item.maxDate).toLocaleString()}</td>
          <td>{new Date(item.minDate).toLocaleString()}</td>
          <td>{item.reference2}</td>
        </>
      );


    return (
        <div className="basic-info-form-container2">
            <h3 className="basic-info-form-title">Detalles de Deuda </h3>
            <Table
                title={`Detalles de Deuda del ditribuidor (${code})`}
                rows={data}
                columns={columns}
                icon={faFileInvoiceDollar}
                renderRow={renderRow}
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={setCurrentPage}
                onRefresh={handleRefresh}
                loading={loading}
                flagPagination={true}
            />
            <div className="basic-form-footer">
                <button
                    type="submit"
                    className="basic-custom-button"
                    onClick={closeModal}
                >
                    <FontAwesomeIcon icon={faClose} className="basic-shortcut-icon" />
                    Cerrar
                </button>

            </div>
            {loading && <Loader />}
            <SuccessNotification
                message={"Se ha cargado correctamente"}
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message="Ups! Verifique los datos enviados."
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />

            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isInfoVisible}
                onClose={() => setIsInfoVisible(false)}
            />
        </div>
    );
};

export default DebtDetail;
