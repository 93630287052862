import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import TableModule from '../TableModule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import apiClient from "../../axios";
import LoginNotification from "../Notifications/LoginNotification";

const AddSubscriptionsModal = ({ isVisible, onClose, contractId }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedModules, setSelectedModules] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const itemsPerPage = 10;

    // Procesar los datos obtenidos
    const processFetchedData = (data) => {
        const groupedData = data.reduce((acc, item) => {
            const existing = acc.find((group) => group.moduleId === item.moduleId);
            if (existing) {
                existing.paymentDetails.push({
                    ...item,
                });
            } else {
                acc.push({
                    ...item,
                    paymentDetails: [{ ...item }],
                    selectedPaymentPeriod: item.paymentPeriod,
                    selectedDescription: item.description,
                    selectedId: item.id,
                });
            }
            return acc;
        }, []);

        groupedData.forEach((group) => {
            group.paymentDetails.sort((a, b) => a.paymentPeriod - b.paymentPeriod);
            const firstDetail = group.paymentDetails[0];
            group.selectedPaymentPeriod = firstDetail.paymentPeriod;
            group.selectedDescription = firstDetail.description;
            group.selectedId = firstDetail.id;
        });

        return groupedData;
    };

    // Obtener datos de la API
    const fetchAllData = useCallback(async (page = 1) => {
        try {
            setLoading(true);
            const response = await apiClient.get(`/module_pack?productType=SUBSCRIPTIONS&contract=${contractId}&page=${page}`);
            if (Array.isArray(response.data.results)) {
                setData(processFetchedData(response.data.results));
                setTotalItems(response.data.total);
            } else {
                setData([]);
                setTotalItems(0);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            }
        } finally {
            setLoading(false);
        }
    }, [contractId]);

    useEffect(() => {
        fetchAllData(currentPage);
    }, [currentPage, fetchAllData]); // Añadido 'fetchAllData' a las dependencias

    const handleRefresh = useCallback(() => {
        fetchAllData(currentPage);
    }, [fetchAllData, currentPage]);

    const handleSave = async () => {
        setLoading(true);
        const purchasedModules = data
            .filter((item) => selectedModules.includes(item.moduleCode))
            .map((item) => {
                return {
                    productId: item.moduleId,
                    templateId: item.selectedId,
                    name: item.moduleName,
                    extraUsers: 0
                };
            });
        try {
            const payload = {
                contractId,
                purchasedModules
            };
            const response = await apiClient.post('/orders', payload);
            if (response.status === 201) {
                const orderNumber = response.data.order.id;
                navigate(`/PurchaseSummary/${orderNumber}`);
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsLoginVisible(true);
            }

        } finally {
            setLoading(false);
        }

    };

    const handleCheckboxChange = (moduleCode) => {
        setSelectedModules((prevSelected) =>
            prevSelected.includes(moduleCode)
                ? prevSelected.filter((code) => code !== moduleCode)
                : [...prevSelected, moduleCode]
        );
    };

    const handleSelectAll = () => {
        if (selectedModules.length === data.length) {
            setSelectedModules([]);
        } else {
            setSelectedModules(data.map((item) => item.moduleCode));
        }
    };

    const handlePaymentPeriodChange = (moduleId, newPeriod) => {
        setData((prevData) =>
            prevData.map((item) => {
                if (item.moduleId === moduleId) {
                    const selectedDetail = item.paymentDetails.find(
                        (detail) => detail.paymentPeriod === newPeriod
                    );
                    return {
                        ...item,
                        selectedPaymentPeriod: newPeriod,
                        selectedDescription: selectedDetail?.description || item.description,
                        selectedId: selectedDetail.id
                    };
                }
                return item;
            })
        );
    };

    const renderRow = (item) => {
        const selectedDetail = item.paymentDetails.find(
            (detail) => detail.paymentPeriod === item.selectedPaymentPeriod
        );
        const total = selectedDetail ? selectedDetail.purchasePrice : 0;

        return (
            <>
                <td>{item.moduleName}</td>
                <td>{item.selectedDescription}</td>
                <td>
                    <select
                        value={item.selectedPaymentPeriod}
                        onChange={(e) =>
                            handlePaymentPeriodChange(item.moduleId, Number(e.target.value))
                        }
                    >
                        {item.paymentDetails.map((detail) => (
                            <option key={detail.paymentPeriod} value={detail.paymentPeriod}>
                                {detail.paymentPeriod} meses
                            </option>
                        ))}
                    </select>
                </td>
                <td>${total.toFixed(2)}</td>
                <td className="align-center">
                    <input
                        type="checkbox"
                        checked={selectedModules.includes(item.moduleCode)}
                        onChange={() => handleCheckboxChange(item.moduleCode)}
                    />
                </td>
            </>
        );
    };

    return (
        isVisible && (
            <div className="modal-overlay">
                <div className="modal-content-module">
                    <div className="modal-content-module-body">
                        <h3 className="modal-title ">Suscripciones</h3>
                        <TableModule
                            title="Lista de Suscripciones"
                            rows={data}
                            columns={[
                                { title: "Nombre", key: "description" },
                                { title: "Descripción", key: "description" },
                                { title: "Meses", key: "extraSus" },
                                { title: "Precio Módulo", key: "precioSus" },
                                { title: "Seleccionar", key: "acciones" },
                            ]}
                            icon={faCubes}
                            renderRow={renderRow}
                            currentPage={currentPage}
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            onPageChange={setCurrentPage}
                            onRefresh={handleRefresh}
                            onSelectAll={handleSelectAll}
                            isAllSelected={selectedModules.length === data.length}
                            loading={loading}
                            select={true}
                        />
                    </div>
                    <div className="basic-form-footer">
                        <button className="basic-custom-button" onClick={onClose}>
                            <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                            Cancelar
                        </button>
                        <button className="basic-custom-button" onClick={handleSave} disabled={selectedModules.length === 0}>
                            <FontAwesomeIcon icon={faCheck} className="basic-shortcut-icon" />
                            Aceptar
                        </button>
                    </div>
                </div>
                <LoginNotification
                    message="Vuele a iniciar sesión"
                    isVisible={isLoginVisible}
                    onClose={() => setIsLoginVisible(false)}
                />
            </div>
        )
    );
};

export default AddSubscriptionsModal;
