import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import apiLogin from "../../axios";
import "./Login.css";
import imgAcl from '../../../src/static/ACL.png';
import imgFenix from '../../../src/static/FenixLogo.png';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import LoginModal from '../../components/LoginModal';

function Login({ onLogin }) {
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleOpenModal = () => {
    setResetPassword(true);
  };
  const handleCloseModal = () => {
    setResetPassword(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Hacer la solicitud POST al endpoint /login
      const response = await apiLogin.post("/login", {
        code,
        password,
      });
      if (response.status === 200) {
        // Si la autenticación es exitosa
        setIsSuccessVisible(true);
        onLogin();
        navigate("/HomePage");
        localStorage.setItem("code", code);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.user.role);
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('permissions', JSON.stringify(response.data.user.permissions));
        localStorage.setItem('distributor', response.data.user.distributor);
      } else {
        alert("Credenciales inválidas");
      }
    } catch (error) {
      setIsErrorVisible(true);
    }
  };


  return (
    <div className="login-container">
      <div className="basic-info-form-grid">
        <div className="login__info">
          <div className="wellcome-container">
            <div className="logo-acl">
              <img src={imgAcl} alt="Logo" />
            </div>
            <div className="wellcome-info-group">
              <h2>Bienvenido</h2>
              <p >Sistema de Administración de contratos, módulos y usuarios de acceso a los productos Fenix</p>
            </div>
          </div>
        </div>
        <div className="login__info">
          <div className="credentials-container">
            <div className="credentials-info-group">
              <div className="logo-fenix">
                <img src={imgFenix} alt="Logo" />
              </div>
              <h2>Inicio de Sesión</h2>
              <div className="credentials-basic-info">
                <FontAwesomeIcon icon={faUser} className="icono-login" />
                <input
                  className="text-box-login"
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Usuario"
                />
              </div>
              <div className="credentials-basic-info">
                <FontAwesomeIcon icon={faLock} className="icono-login" />
                <div className="password-input-container">
                  <input
                    className="text-box-login"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Contraseña"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="password-toggle-icon"
                    onClick={toggleShowPassword}
                  />
                </div>
              </div>
              <div className="credentials-buttons-container">
                <button className="access_button" onClick={handleSubmit}>
                  INGRESAR
                </button>
              </div>
              <div className="credentials-buttons-container">
                <button className="link-button" onClick={handleOpenModal}>
                  ¿Olvidaste tu contraseña?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessNotification
        message={"Ingreso Correcto"}
        isVisible={isSuccessVisible}
        onClose={() => setIsSuccessVisible(false)}
      />
      <ErrorNotification
        message="Ups! Credenciales Incorrectas"
        isVisible={isErrorVisible}
        onClose={() => setIsErrorVisible(false)}
      />
      {resetPassword && (
        <LoginModal
          isVisible={resetPassword}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}

export default Login;