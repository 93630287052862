import React, { useState, useEffect, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import TableModule from '../../components/TableModule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import apiClient from "../../axios";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import Loader from "../../components/Loader";


const AddModulesModal = ({ isVisible, onClose,  contractId }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedModules, setSelectedModules] = useState([]); // Usar el valor inicial pasado por las props
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const itemsPerPage = 10;
    const [subscriptionCounts, setSubscriptionCounts] = useState({});
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [mensage, setMensaje] = useState("");

    const fetchAllData = useCallback(async (page = 1) => {
        try {
            setLoading(true);
            const response = await apiClient.get(`/module_pack?productType=EXTRAS&contract=${contractId}&page=${page}`);
            if (Array.isArray(response.data.results)) {
                setData(response.data.results);
                setTotalItems(response.data.total);
            } else {
                setData([response.data.results]);
                setTotalItems(1);
            }
        } catch (error) {
            console.error("Error al obtener los datos de modulos extras", error);
        } finally {
            setLoading(false);
        }
    }, [contractId]);

    useEffect(() => {
        fetchAllData(currentPage);
    }, [currentPage, fetchAllData]); // Añadido 'fetchAllData' a las dependencias
    
    const handleRefresh = useCallback(() => {
        fetchAllData(currentPage);
    }, [fetchAllData, currentPage]);

    useEffect(() => {
        if (isVisible) {
            handleRefresh();
        }
    }, [isVisible, handleRefresh]);

    const handleSave = async () => {
        setLoading(true);
        const purchasedModules = data
        .filter(item => selectedModules.includes(item.moduleCode))
        .map((item) => {
            const index = data.findIndex(d => d.moduleCode === item.moduleCode); // Encontrar el índice correcto
            return {
                productId: item.moduleId,
                templateId: item.id,
                name: item.moduleName,
                extraUsers: subscriptionCounts[index] || 0
            };
        });
        try{
            const payload = {
                contractId,
                purchasedModules
            };
            const response = await apiClient.post('/orders', payload);
            if (response.status === 201) {
                setIsSuccessVisible(true);
                const orderNumber = response.data.order.id;
                navigate(`/PurchaseSummary/${orderNumber}`);
            }

        }catch(error){
            if (error.response.data.message.startsWith("THIS PRODUCT IS ALREADY BOUGHT")) {
                setMensaje("El modulo ya existe dentro del contrato.");
            }
            else if (error.response.data.message.startsWith("PRODUCT_TYPE_NOT_FOUND")) {
                const mensajeCompleto = error.response.data.message;
                const detalle = mensajeCompleto.split("PRODUCT_TYPE_NOT_FOUND")[1].trim(); 
                setMensaje(`El modulo ${detalle} No existe.`);
            }
            else{
                setMensaje("Hubo un problema al Crear la Orden. Inténtalo de nuevo.");
            }
            setIsErrorVisible(true);
        }finally{
            setLoading(false);
        }
        
    };

    const columns = [
        { title: "Nombre", key: "description" },
        { title: "Descripción", key: "description" },
        { title: "Precio Modulo", key: "precioCompra" },
        { title: "Usuarios Extras", key: "extraSus" },
        { title: "Total", key: "precioSus" },
        { title: "Seleccionar", key: "acciones" }
    ];

    const handleIncrement = (index) => {
        setSubscriptionCounts((prevCounts) => ({
            ...prevCounts,
            [index]: (prevCounts[index] || 0) + 1,
        }));
    };

    const handleDecrement = (index) => {
        setSubscriptionCounts((prevCounts) => {
            const newCount = (prevCounts[index] || 0) - 1;
            return {
                ...prevCounts,
                [index]: newCount < 0 ? 0 : newCount,
            };
        });
    };

    const handleCheckboxChange = (moduleCode) => {
        setSelectedModules(prevSelected =>
            prevSelected.includes(moduleCode)
                ? prevSelected.filter(code => code !== moduleCode)
                : [...prevSelected, moduleCode]
        );
    };

    const handleSelectAll = () => {
        if (selectedModules.length === data.length) {
            setSelectedModules([]);
        } else {
            setSelectedModules(data.map(item => item.moduleCode));
        }
    };

    const renderRow = (item, index) => {
        const count = subscriptionCounts[index] || 0;
        const total = count * item.perUserPrice + item.purchasePrice;
        return (
            <>
                <td>{item.moduleName}</td>
                <td>{item.description}</td>
                <td >${item.purchasePrice.toFixed(2)}</td>
                <td>
                    {item.perUserPrice !== 0 ? (
                        <>
                            <div className="sale-counter2">
                                <button onClick={() => handleDecrement(index)}>-</button>
                                <span>{count}</span>
                                <button onClick={() => handleIncrement(index)}>+</button>
                            </div>
                            <div className="align-center">${item.perUserPrice.toFixed(2)} C/U</div>
                        </>
                    ) : (
                        <div className="align-center">-</div>
                    )}
                </td>
                <td>${total.toFixed(2)}</td>
                <td className="align-center">
                    <input
                        type="checkbox"
                        checked={selectedModules.includes(item.moduleCode)}
                        onChange={() => handleCheckboxChange(item.moduleCode)}
                    />
                </td>
            </>
        );
    };

    return (
        isVisible && (
            <div className="modal-overlay">
                <div className="modal-content-module">
                    <div className="modal-content-module-body">
                        <h3 className="modal-title ">Módulos Extras</h3>
                        <TableModule
                            title="Lista de Módulos"
                            rows={data}
                            columns={columns}
                            icon={faCubes}
                            renderRow={renderRow}
                            currentPage={currentPage}
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            onPageChange={setCurrentPage}
                            onRefresh={handleRefresh}
                            onSelectAll={handleSelectAll}
                            isAllSelected={selectedModules.length === data.length}
                            loading={loading}
                            select={true}
                        />
                    </div>
                    <div className="basic-form-footer">
                        <button
                            className="basic-custom-button"
                            onClick={onClose}
                        >
                            <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                            Cancelar
                        </button>
                        <button className="basic-custom-button" onClick={handleSave} disabled={selectedModules.length === 0}>
                            <FontAwesomeIcon icon={faCheck} className="basic-shortcut-icon" />
                            Aceptar
                        </button>
                    </div>
                </div>
                <SuccessNotification
                    message={"Orden Creada"}
                    isVisible={isSuccessVisible}
                    onClose={() => setIsSuccessVisible(false)}
                    position="center"
                />
                <ErrorNotification
                    message={mensage}
                    isVisible={isErrorVisible}
                    onClose={() => setIsErrorVisible(false)}
                />
                <LoginNotification
                    message="Vuele a iniciar sesión"
                    isVisible={isLoginVisible}
                    onClose={() => setIsLoginVisible(false)}
                />
                {loading && <Loader />}
            </div>
        )
    );
};

export default AddModulesModal;
