import React, { useState, useEffect } from "react";
import TableModule from "../../components/TableModule";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCubes, faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useCallback } from "react";
import apiClient from "../../axios";

const ComboModal = ({ isVisible, onClose, onAddModules, initialSelectedModule }) => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedModule, setSelectedModule] = useState(initialSelectedModule || null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const itemsPerPage = 10;

    const processFetchedData = (data) => {
        const groupedData = data.reduce((acc, item) => {
            const existing = acc.find((group) => group.moduleId === item.moduleId);
            if (existing) {
                existing.paymentDetails.push({ ...item });
            } else {
                acc.push({
                    ...item,
                    paymentDetails: [{ ...item }],
                    selectedPaymentPeriod: item.paymentPeriod,
                    selectedDescription: item.description,
                });
            }
            return acc;
        }, []);

        groupedData.forEach((group) => {
            group.paymentDetails.sort((a, b) => a.paymentPeriod - b.paymentPeriod);
            const firstDetail = group.paymentDetails[0];
            group.selectedPaymentPeriod = firstDetail.paymentPeriod;
            group.selectedDescription = firstDetail.description;
        });

        return groupedData;
    };

    const fetchAllData = useCallback(async (page = 1) => {
        try {
            setLoading(true);
            const response = await apiClient.get(`/module_pack?productType=COMBOS&page=${page}`);
            if (Array.isArray(response.data.results)) {
                setData(processFetchedData(response.data.results));
                setTotalItems(response.data.total);
            } else {
                setData([]);
                setTotalItems(0);
            }
        } catch (error) {
            console.error("Error al obtener los datos del servicio", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchAllData(currentPage);
    }, [currentPage, fetchAllData]); // Añadido 'fetchAllData' a las dependencias

    const handleRefresh = useCallback(() => {
        fetchAllData(currentPage);
    }, [fetchAllData, currentPage]);

    useEffect(() => {
        if (isVisible) {
            setSelectedModule(initialSelectedModule || null);
        }
    }, [isVisible, initialSelectedModule]);

    const handleSave = () => {
        const selectedData = data
            .filter((item) => selectedModule.includes(item.moduleCode))
            .map((item) => {
                const selectedDetail = item.paymentDetails.find(
                    (detail) => detail.paymentPeriod === item.selectedPaymentPeriod
                );
                return {
                    ...selectedDetail,
                    count: 0,
                };
            });

        onAddModules(selectedData);
        onClose();
    };

    const handleCheckboxChange = (moduleCode) => {
        setSelectedModule((prevSelected) => (prevSelected === moduleCode ? null : moduleCode));
    };

    const handlePaymentPeriodChange = (moduleId, newPeriod) => {
        setData((prevData) =>
            prevData.map((item) => {
                if (item.moduleId === moduleId) {
                    const selectedDetail = item.paymentDetails.find(
                        (detail) => detail.paymentPeriod === newPeriod
                    );
                    return {
                        ...item,
                        selectedPaymentPeriod: newPeriod,
                        selectedDescription: selectedDetail?.description || item.description,
                    };
                }
                return item;
            })
        );
    };

    const renderRow = (item) => {
        const selectedDetail = item.paymentDetails.find(
            (detail) => detail.paymentPeriod === item.selectedPaymentPeriod
        );
        const total = selectedDetail ? selectedDetail.purchasePrice : 0;

        return (
            <>
                <td>{item.moduleName}</td>
                <td>{item.selectedDescription}</td>
                <td>
                    <select
                        value={item.selectedPaymentPeriod}
                        onChange={(e) =>
                            handlePaymentPeriodChange(item.moduleId, Number(e.target.value))
                        }
                    >
                        {item.paymentDetails.map((detail) => (
                            <option key={detail.paymentPeriod} value={detail.paymentPeriod}>
                                {detail.paymentPeriod} meses
                            </option>
                        ))}
                    </select>
                </td>
                <td>${total.toFixed(2)}</td>
                <td className="align-center">
                    <input
                        type="checkbox"
                        checked={selectedModule === item.moduleCode}
                        onChange={() => handleCheckboxChange(item.moduleCode)}
                    />
                </td>
            </>
        );
    };

    return (
        isVisible && (
            <div className="modal-overlay">
                <div className="modal-content-module">
                    <div className="modal-content-module-body">
                        <h3 className="modal-title">COMBOS</h3>
                        <TableModule
                            title="Lista de COMBOS"
                            rows={data}
                            columns={[
                                { title: "Nombre", key: "description" },
                                { title: "Descripción", key: "description" },
                                { title: "Meses", key: "extraSus" },
                                { title: "Precio Módulo", key: "precioSus" },
                                { title: "Seleccionar", key: "acciones" },
                            ]}
                            icon={faCubes}
                            renderRow={renderRow}
                            currentPage={currentPage}
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            onPageChange={setCurrentPage}
                            onRefresh={handleRefresh}
                            isAllSelected={selectedModule && data.length === 1}
                            loading={loading}
                        />
                    </div>
                    <div className="basic-form-footer">
                        <button className="basic-custom-button" onClick={onClose}>
                            <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                            Cancelar
                        </button>
                        <button className="basic-custom-button" onClick={handleSave}>
                            <FontAwesomeIcon icon={faCheck} className="basic-shortcut-icon" />
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default ComboModal;
